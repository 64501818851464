<template>
  <div>
    <b-button class="m-1" variant="success" @click="showAlertSuccess('small')">Success small</b-button>
    <b-button class="m-1" variant="success" @click="showAlertSuccess('medium')">Success medium</b-button>
    <b-button class="m-1" variant="success" @click="showAlertSuccess('large')">Success large</b-button>
    <b-button class="m-1" variant="danger" @click="showAlertError('small')">Error small</b-button>
    <b-button class="m-1" variant="danger" @click="showAlertError('medium')">Error medium</b-button>
    <b-button class="m-1" variant="danger" @click="showAlertError('large')">Error large</b-button>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  methods: {
    showAlertSuccess(size) {
      this.$newSuccess('This is the success title', 'This is the success message', size)
    },
    showAlertError(size) {
      this.$newError('This is the error title', 'This is the error message', size)
    }
  }
}
</script>
